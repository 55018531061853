import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../views/layout/Layout.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    // 重定向
    // redirect: '/home',
    // children: [
    //   { path: 'home', component: () => import('../views/home/Home.vue') },
    // ],
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = new VueRouter({
  routes,
});

export default router;
